<template>
  <div class="d-flex">
    <div
      :style="{ width: `${sidebarSize}px` }"
      class="d-flex ant-glass-background radius-0 ant-border-right pl-5 py-5"
    >
      <div class="flex-1 overflow-hidden">
        <v-list class="bg-transparent" dense>
          <v-subheader> Admin</v-subheader>
          <v-list-item-group v-model="selectedView" color="primary">
            <v-list-item
              v-if="$can('access', 'admin-license-modules')"
              @click="$router.push({ name: 'admin-modules' })"
            >
              <v-list-item-icon>
                <v-icon>mdi-view-module</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Modules</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="$router.push({ name: 'admin-projects' })">
              <v-list-item-icon>
                <v-icon>v</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Projects</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="$can('access', 'admin-users')"
              @click="$router.push({ name: 'admin-users' })"
            >
              <v-list-item-icon>
                <v-icon>mdi-account-group-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Users</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="$router.push({ name: 'admin-collections' })">
              <v-list-item-icon>
                <v-icon>mdi-dock-bottom</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Collections</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="$router.push({ name: 'admin-labels' })">
              <v-list-item-icon>
                <v-icon>mdi-label-multiple-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Labels</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="$can('access', 'admin-account-companies')"
              @click="$router.push({ name: 'admin-companies' })"
            >
              <v-list-item-icon>
                <v-icon>mdi-factory</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Companies</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
      <resize-horizontal @size="setSize" />
    </div>
    <transition mode="out-in" name="fade">
      <router-view class="flex-grow-1" style="min-height: 0" />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ResizeHorizontal from '@/components/ResizeHorizontal';

export default {
  name: 'Admin',
  components: { ResizeHorizontal },
  data: () => {
    return {
      selectedView: 0,
      sidebarSize: 300,
    };
  },
  computed: {
    ...mapGetters(['authenticatedUser']),
  },
  watch: {
    $route: {
      handler(route) {
        const views = [
          'admin-modules',
          'admin-projects',
          'admin-users',
          'admin-collections',
          'admin-labels',
          'admin-companies',
        ];
        if (!this.$can('access', 'admin-license-modules')) {
          views.shift();
        }
        this.selectedView = views.indexOf(route.name);
      },
      immediate: true,
      deep: true,
    },
  },

  mounted() {
    this.$can('access', 'admin-license-modules')
      ? this.$router.push({ name: 'admin-modules' })
      : this.$router.push({ name: 'admin-projects' });
  },
  methods: {
    setSize(size) {
      this.sidebarSize = size;
    },
  },
};
</script>

<style lang="scss" scoped>
.admin-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 100%;

  .admin-navigation-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .admin-navigation {
      display: flex;
    }
  }
}
</style>
