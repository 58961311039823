/**
 * https://gist.github.com/joseluisq/819d694db6fd675deae7270b4e55b3ac
 */
export default function slugify(text, ampersand = 'and') {
  const a = 'àáäâèéëêìíïîòóöôùúüûñçßÿỳýœæŕśńṕẃǵǹḿǘẍźḧ';
  const b = 'aaaaeeeeiiiioooouuuuncsyyyoarsnpwgnmuxzh';
  const p = new RegExp(a.split('').join('|'), 'g');

  if (text !== undefined) {
    return text
      .toString()
      .toLowerCase()
      .replace(/[\s_]+/g, '-')
      .replace(p, (c) => b.charAt(a.indexOf(c)))
      .replace(/&/g, `-${ampersand}-`)
      .replace(/[^\w-]+/g, '')
      .replace(/--+/g, '-')
      .replace(/^-+|-+$/g, '');
  } else {
    return '';
  }
}
