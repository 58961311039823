<template>
  <div
    class="align-center justify-center d-flex"
    style="cursor: e-resize"
    @mousedown="startResizeSidebar"
  >
    <v-icon> mdi-drag-vertical-variant</v-icon>
  </div>
</template>

<script>
export default {
  name: 'ResizeHorizontal',
  methods: {
    startResizeSidebar(e) {
      document.addEventListener('mousemove', this.dragSidebar);
      document.addEventListener('mouseup', this.stopResizeSidebar);
    },
    dragSidebar(e) {
      if (e.clientX + 12 >= 44) {
        this.$emit('size', e.clientX + 12);
      }
    },

    stopResizeSidebar(e) {
      document.removeEventListener('mousemove', this.dragSidebar);
      this.$emit('resizeStop');
      document.removeEventListener('mouseup', this.stopResizeSidebar);
    },
  },
};
</script>

<style scoped></style>
