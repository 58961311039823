<template>
  <div>
    <dynamic-data-table
      :auto-sort-column="'name'"
      :can-delete="true"
      :can-edit="true"
      :can-refresh="true"
      :is-loading="companiesStatus === 'loading'"
      :table-headers="tableHeaders"
      :table-height="`${tableHeight}px`"
      :table-records="companies"
      class="ant-glass-background radius-0"
      table-title="Companies"
      @deleteItem="setupDelete"
      @editItem="setupEdit"
      @reloadData="fetchCompanies"
    >
      <template #table-actions>
        <v-dialog
          key="add-edit-company"
          v-model="companyDialog"
          max-width="500px"
          @click:outside="closeCompanyDialog"
        >
          <template #activator="{ on }">
            <v-tooltip bottom>
              <template #activator="{ on: onTooltip }">
                <v-icon class="ant-icon" dense v-on="{ ...on, ...onTooltip }">
                  mdi-plus
                </v-icon>
              </template>
              <span>New company</span>
            </v-tooltip>
          </template>
          <v-card>
            <v-card-title class="justify-center text-uppercase headline">
              {{ companyItem.id ? 'Edit' : 'Add' }} Company
            </v-card-title>
            <v-divider />
            <v-form ref="form" @submit.prevent>
              <div class="px-10 pb-5">
                <ant-input :is-optional="false" label="Name">
                  <template #input-field>
                    <v-text-field
                      v-model="companyItem.name"
                      :rules="[rules.required]"
                      counter="45"
                      dense
                      filled
                      hide-details
                      maxlength="45"
                      placeholder="Name"
                      single-line
                    />
                  </template>
                </ant-input>
              </div>
              <v-card-actions class="ant-border-top ant-dialog-actions-bg">
                <v-spacer />
                <v-btn color="error" small text @click="closeCompanyDialog()">
                  Cancel
                </v-btn>
                <v-btn color="primary" elevation="0" small @click="saveCompany">
                  Save
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </template>
    </dynamic-data-table>
    <delete-dialog
      :dialog="companyDeleteDialog"
      :title="`Are you sure you want to remove company: ${companyItem.name}?`"
      @closeDialog="closeCompanyDialog"
      @deleteAction="deleteCompany"
    />
  </div>
</template>

<script>
import companies from '@/store/admin/companies';
import { mapGetters } from 'vuex';
import DynamicDataTable from '@/components/DynamicDataTable';
import DeleteDialog from '@/components/DeleteDialog';
import AntInput from '@/components/AntInput.vue';

const name = 'ACompanies';

export default {
  name: name,
  components: { AntInput, DeleteDialog, DynamicDataTable },
  data: () => {
    return {
      tableHeaders: [
        { text: 'Name', value: 'name' },
        { text: 'Actions', value: 'actions', align: 'right', sortable: false },
      ],
      companyDialog: false,
      companyDeleteDialog: false,
      companyItem: {},
      rules: {
        required: (value) => !!value || 'Required.',
      },
      tableHeight: '100%',
    };
  },
  computed: {
    ...mapGetters(['companies', 'companiesStatus']),
  },
  created() {
    this.$store.registerModule(name, companies);
  },
  mounted() {
    this.fetchCompanies();
    this.tableHeight = window.innerHeight - 59 - 64 - 45;
  },
  destroyed() {
    this.$store.unregisterModule(name);
  },
  methods: {
    fetchCompanies() {
      this.$store.dispatch('fetchCompanies');
    },
    closeCompanyDialog() {
      this.companyDialog = false;
      this.companyDeleteDialog = false;
      this.companyItem = Object.assign({}, {});
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
    },
    saveCompany() {
      if (this.$refs.form.validate()) {
        if (this.companyItem.id !== undefined) {
          // update company
          this.$store
            .dispatch('updateCompany', { company: this.companyItem })
            .then(() => {
              this.closeCompanyDialog();
            });
        } else {
          // create company
          this.$store
            .dispatch('createCompany', { company: this.companyItem })
            .then(() => {
              this.closeCompanyDialog();
            });
        }
      }
    },
    setupEdit(company) {
      this.companyItem = Object.assign({}, company);
      this.companyDialog = true;
    },
    setupDelete(company) {
      this.companyItem = Object.assign({}, company);
      this.companyDeleteDialog = true;
    },
    deleteCompany() {
      this.$store
        .dispatch('deleteCompany', { company: this.companyItem })
        .then(() => {
          this.closeCompanyDialog();
        });
    },
  },
};
</script>

<style scoped></style>
